body {
  margin: 0;
  padding: 0;
}

h1 {
  margin: 12px 0 12px 0;
}

@media (max-width: 600px) {
  .ant-btn {
    width: 100%;
    padding: 4px 8px;
  }
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

button#rc-tabs-7-more > span > svg {
bottom: -6px;
position: relative;
}

.active {
  color: #1890ff;
}

.logo img {
  height: 48px;
}

.ant-tabs-tab {
  padding: 12px 12px 8px 8px !important;
  margin-right: 8px !important;
}

.ant-tabs-tab-btn > a > span {
  margin-right: 8px !important;
}

:where(.css-dev-only-do-not-override-1ck3jst).ant-tabs .ant-tabs-tab+.ant-tabs-tab {
  margin-left: 0px !important;
}

div.sider-tabs-mobile > div.ant-tabs-nav { 
  margin-bottom: 0;
  background-color: white;
  height: 48px;
}

div.ant-menu-item-group-title {
  font-size: 12px !important;
}

.ellipsis {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 13ch;
}
.button-hidden {
  visibility: hidden;
}

.message:hover .button-hidden {
  visibility: visible;
}

.ant-modal.css-dev-only-do-not-override-1ck3jst {
  max-width: calc(100vw - 16px) !important;
}
  
.custom-collapse .ant-collapse-content-box {
  background: #f5f5f5;
  padding: 24px 48px 24px 48px !important;
  display: flex;
  justify-content: center;
}
@media (max-width: 600px) {
  .custom-collapse .ant-collapse-content-box {
    padding: 12px !important;
  }
}

.ant-card-body {
  height: 100%;
}

div.radio-group > label.ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled) {
  background-color: #f6f9fd !important;
  border-color: #1677ff;
  color: #1677ff !important;
  font-weight: 500;
}

div.radio-group > label.ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled) > span > #radio-price {
  color: #1677ff !important;
}

.textarea.ant-input.css-dev-only-do-not-override-1ck3jst {
    min-height: 180px;
}


button#rc-tabs-9-more {
  padding: 0px 16px !important;
  font-size: 16px;
  font-weight: 900;
  background-color: transparent;
}
.radio-group {
  display: flex;
  justify-content: space-between;
}

div.radio-group label.ant-radio-button-wrapper {
  flex-grow: 1;
  text-align: center;
  height: 100%;
  width: 100%;
}

.site-form-item-icon {
  color: lightgrey;
  margin-right: 4px;
}

.ant-form-item {
  margin-bottom: 16px !important;
}
ul > li > span > a {
  display: flex;
  gap: 8px !important;
}

:where(.css-dev-only-do-not-override-1ck3jst).ant-breadcrumb .ant-breadcrumb-separator {
  color: rgb(177, 177, 177);
}

.ant-drawer-content-wrapper {
  max-width: 240px !important;
  min-width: min-content !important;
}

button.ant-btn.css-dev-only-do-not-override-1ck3jst.ant-btn-primary.ant-btn-lg.ant-btn-block {
  border-radius: 5px !important;
}

#rc-tabs-25-more-popup > li > span > a {
  display: flex;
  gap: 8px !important;
}

.ant-slider-horizontal {
  width: "100%"
}  